import React from "react"
import styled from "styled-components"
import SiteFooterComponent from "./FooterComponent"


const SiteFooter = () => (

<FooterContainer>
<Footer>
<SiteFooterComponent 
  Title="Logo"
  Button="testing"
/>
<SiteFooterComponent 
  Title="Product"
/>
<SiteFooterComponent 
  Title="Help"
/>
<SiteFooterComponent 
  Title="Contact"
/>

</Footer>
</FooterContainer>

)

export default SiteFooter

const FooterContainer = styled.div`
  background-color: black;
`;

const Footer = styled.div`
  display: grid;
  margin: 0px auto;
  max-width: 1024px;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    "col_one col_two col_three col_four"
    ". . . .";

`;

