import React from "react"
import styled from "styled-components"

// Import Components
import SiteFooter from "../components/Footer"
import Navbar from "../components/Navbar"
import ButtonPurchase from "../components/ButtonPurchase"
import ButtonLearnMore from "../components/ButtonLearnMore"

// Import Assets
import logo from "../images/showcase-img.png"

const IndexPage = () => (
  <Container>
    <Navbar></Navbar>
    <SiteHeader>
      <Headline>Multi-Tasking. Unleashed.</Headline>
      <SubTitle>Introducing Buoy, the persistant floating window app.</SubTitle>
      <SubTitle>Now you can do two things, at once.</SubTitle>
      <ButtonContainer>
        <ButtonPurchase />
        <ButtonLearnMore />
      </ButtonContainer>
      <ShowcaseContainer>
        <img src={logo} alt="Logo" />
      </ShowcaseContainer>
    </SiteHeader>

    <DividerContainer>
      <p>
        🔥🔥To celebrate the launch, use discount code zissou to save 50% until
        October 3rd 🔥🔥{" "}
      </p>
    </DividerContainer>

    <FeatureHeadlineContainer>
      <h1>Wave goodbye to alt/cmd-tab</h1>
      <p>It's time to get back to work.</p>
    </FeatureHeadlineContainer>

    <FeatureLeftContainer>
      <div className="TextLeft">
        <h2>Life before buoy</h2>
        <p>Works on Mac, Windows and Linux desktops</p>
      </div>
      <div>
        <img src={logo} alt="Logo" />
      </div>
    </FeatureLeftContainer>

    <FeatureRightContainer>
      <div>
        <img src={logo} alt="Logo" />
      </div>
      <div className="TextRight">
        <h2>Life after buoy 🎉</h2>
        <p>Works on Mac, Windows and Linux desktops</p>
      </div>
    </FeatureRightContainer>

    <FeatureLeftContainer>
      <div className="TextLeft">
        <h2>Browser Extension</h2>
        <p>Chrome & Firefox</p>
      </div>
      <div>
        <img src={logo} alt="Logo" />
      </div>
    </FeatureLeftContainer>

    <PurchaseContainer>
      <h1>Purchase</h1>
    </PurchaseContainer>

    <SiteFooter></SiteFooter>
  </Container>
)

export default IndexPage

const Container = styled.div`
  @import url("https://rsms.me/inter/inter.css");
  html {
    font-family: "Inter", sans-serif;
  }
  @supports (font-variation-settings: normal) {
    html {
      font-family: "Inter var", sans-serif;
    }
  }
`

const SiteHeader = styled.div`
  display: grid;
  margin: 40px auto;
  max-width: 1024px;
  background: #daecf3;
`

const Headline = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 44px;
  text-align: center;
  color: #000000;
  margin-bottom: 19px;
`

const SubTitle = styled.text`
  font-family: "Inter";
  font-size: 1.5rem;
  line-height: 29px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
`

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  margin-top: 20px;
`
const ShowcaseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 46px;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  img {
    max-width: 100%;
  }
`

const DividerContainer = styled.div`
  margin-top: -6.5rem;
  background: #243044;
  height: 225px;
  width: 100%;
  p {
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding-top: 6.5rem;
  }
`

const FeatureHeadlineContainer = styled.div`
  margin-top: 2.88em;
  text-align: center;
  p {
    font-family: "Inter";
    font-size: 24px;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.8);
  }
`

const FeatureLeftContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  margin-top: 160px;
  .TextLeft {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: bold;
      font-size: 2rem;
      line-height: 39px;
      letter-spacing: -1px;
      color: #333333;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 28px;
      color: rgba(51, 51, 51, 0.8);
    }
  }
`

const FeatureRightContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  margin-top: 160px;
  .TextRight {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: bold;
      font-size: 2rem;
      line-height: 39px;
      letter-spacing: -1px;
      color: #333333;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 28px;
      color: rgba(51, 51, 51, 0.8);
      overflow-wrap: break-word;
    }
  }
`

const PurchaseContainer = styled.div`
  background: #5d38db;
  background-size: cover;
  margin: 0 auto;
  max-width: 1024px;
  padding: 4rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;

  transform: translateZ(0);
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease;
  z-index: 10;
  overflow: hidden;

  :hover {
    box-shadow: rgba(24, 18, 41, 0.1) 0px 3px 7px 0px,
      rgba(24, 18, 41, 0.1) 0px 5px 24px 1px;
    transform: scale3d(1.05, 1.05, 1.05);
  }

  h1 {
    font-size: 4rem;
    padding-bottom: 1rem;
    color: #fff;
  }

  /* backface-visibility: hidden;
  background-color: #000;
  background-image: url(../img/shop/startup3-bg.png);
  background-repeat: no-repeat no-repeat;
  background-size: cover;
  border-radius: 3px;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  margin: 0;
  min-height: 414px;
  overflow: hidden;
  padding: 65px 60px;
  position: relative;
  text-decoration: none;
  transform: translateZ(0);
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease;
  user-select: text;
  z-index: 10;

  :hover {
    box-shadow: #000 0 30px 70px;
    transform: scale3d(1.05, 1.05, 1.05);
  } */
`
