import React from "react"
import styled from "styled-components"



const SiteFooterComponent = (props) => (

<FooterComponent>
<FooterTitle>{props.Title}</FooterTitle>
<MenuItem>{props.Button}</MenuItem>
</FooterComponent>

)

export default SiteFooterComponent

const FooterComponent = styled.div`
  display: inline-grid;
  margin: 0px auto;
  grid-auto-flow: row;
  max-width: 1024px;
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
  font-family: 'Inter';
`;

const MenuItem = styled.button`
  color: #fff;
  background: transparent;
  border: transparent;
`;

const FooterTitle = styled.text`
  text-transform: uppercase;
  opacity: 0.55;
  color: #fff;
  font-weight: 600;
`;