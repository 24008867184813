import React from "react"
import styled from "styled-components"

const ButtonPurchase = () => (
  <ButtonComponent>Get it now for $10</ButtonComponent>
)

export default ButtonPurchase

const ButtonComponent = styled.button`
  font-family: "Inter";
  font-weight: 500;
  background-color: #5d38db;
  border: 0 solid #e0e0e0;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
  color: #fff;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  :hover {
    transform: translateY(-1px);
    box-shadow: rgba(24, 18, 41, 0.1) 0px 3px 7px 0px,
      rgba(24, 18, 41, 0.1) 0px 5px 24px 1px;
  }
  @media (min-width: 700px) {
    max-width: 195px;
    justify-self: end;
  }
`
