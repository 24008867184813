import React from "react"
import styled from "styled-components"
// import "../styles/milligram.css"
// import logo from '../images/buoy-logo.svg';

// Import Assets
import BuoyLogo from "../images/buoy-logo.svg"

const Navbar = () => (
  <SiteNavbar>
    <div className="NavLeft">
      <img src={BuoyLogo} alt="Logo" className="BuoyLogo" />
    </div>
    <div className="NavRight">
      <a className="NavLink">FAQ</a>
      <a className="NavLink">Report a bug</a>
    </div>
  </SiteNavbar>
)

export default Navbar

const SiteNavbar = styled.div`
  display: grid;
  margin: 20px auto;
  max-width: 1024px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

  .NavLeft {
    justify-self: start;
    align-self: center;
    .BuoyLogo {
      margin-bottom: none !important;
      /* transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      :hover {
        transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        transform: translateY(-2px);
      } */
    }
  }

  .NavRight {
    justify-self: end;
    align-self: center;
    .NavLink {
      font-family: "Inter";
      padding-left: 20px;
      font-weight: 600;
      font-size: 18px;
    }
  }

  @media (max-width: 400px) {
    .NavLeft {
      justify-self: center;
    }
    .NavRight {
      display: none;
    }
  }
`
